<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl Created ME-ID Track
            .header-cta
                .flex.items-center
                    .block
                        button.btn-primary.m-btn-icon(
                            v-if="checkFeatureByAcl('me_id_track', 'create')"
                            @click="openModal(true)"
                        )
                            IconSet(type="add")
                            span.m-hide.ml-3 New ME-ID Track

        .card--table__body
            .box-filter-table.p-4.pt-0
                .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                    input.field-style(
                        v-model="keyword"
                        type="text"
                        placeholder="Search IMEI..."
                        @keyup="searchKeyword"
                    )
                    .field-icon
                        IconSet(
                            type="search"
                        )
                //- .field.mr-3( style="min-width: 180px;")
                    select.field-style()
                        option All Operator
                        option Operator 1
                        option Operator 2
                    .field-icon
                        IconSet(
                            type="chevron-down"
                        )
                .field.box-find(class="lg:mr-3 lg:mb-0 mr-0 mb-4")
                    .relative
                        .field.relative.w-full
                            input.field-style(
                                placeholder="Select user (write minimum 3 characters)"
                                v-model="user_keyword"
                                @keyup="searchUser()"
                            )
                            .field-icon
                                IconSet(type="search")

                            .box-find-selected(v-if="user_selected")
                                .flex.items-center.justify-between
                                    .flex.items-center
                                        figure.mr-3
                                            //- img.w-6.h-6.rounded-full.object-fit(src="https://randomuser.me/api/portraits/men/41.jpg")
                                        span {{user_keyword}}
                                    button.btn-icon-24(@click="setEmptyUser" v-tooltip="'Remove user'")
                                        IconSet(type="close")

                        .p-4.absolute.container-box-users(v-if="status_users.status === 'loading'")
                            .flex.flex-wrap.items-center.justify-center
                                Spinner(show="true" size="24")
                        .p-4.absolute.container-box-users(v-else-if="user_keyword.length > 2 && list_users.data && list_users.data.length > 0")
                            .small-heading Choose User
                            .box-users
                                .item-user(
                                    v-for="(user, index) in list_users.data"
                                    :key="user.id"
                                    @click="selectUser(user)"
                                )
                                    span {{ user.name }}
                        .p-4.absolute.container-box-users(v-else-if="list_users.data && list_users.data.length == 0")
                            | No data available.

                .field(class="lg:mr-3 lg:mb-0 lg:w-32 mr-0 w-full mb-4")
                    select.field-style(@change="sizeChange($event)" v-model="items_per_page")
                        option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                    .field-icon
                        IconSet(
                            type="chevron-down"
                        )

            .box-table.box-table--target
                table.table
                    thead.m-hide
                        tr
                            th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                    tbody
                        tr(v-if="status_list.status === 'loading'")
                            td.text-center(:colspan="tableThead.length")
                                .flex.flex-wrap.items-center.justify-center
                                    Spinner(show="true" size="48")
                        template(v-else-if="list && list.data && list.data.length > 0")
                            tr(v-for="(data,index) in list.data" :key="data.id")
                                td {{ data.imei?data.imei:'-' }}
                                td {{ toString(data.cases) }}
                                td(style="padding-right:0px !important")
                                    .text-sm.truncate(style="width: 240px;") {{ data.description?data.description:'-' }}
                                td
                                    .w-48
                                        template(
                                            v-if="!checkFeatureByAcl('me_id_track', 'create') && data?.notes?.notes == undefined"
                                        ) -
                                        button.link.text-sm.truncate.text-left(style="width: 240px;"
                                            v-else
                                            @click="getNotes(true, data.id, data.imei)"
                                        ) {{data?.notes&&data?.notes?.notes?data?.notes?.notes:'Add a note'}}

                                td {{ data.created_by && data.created_by.name?data.created_by.name:'-' }}
                                td(style="min-width: 220px;") {{ data.created_at?data.created_at:'-' }}
                                td {{ data.stoped_at?data.stoped_at:'-' }}
                                td
                                    .flex.items-center.justify-end.w-full(
                                        v-if="checkFeatureByAcl('me_id_track', 'show')"
                                    )
                                        button.btn-icon-40(
                                            v-if="data.status == 0"
                                            @click="monitoringAction(1,data)" 
                                            v-tooltip="'Start Monitoring'"
                                        )
                                            IconSet(type="play")
                                        button.btn-icon-40.text-red-500(
                                            v-else-if="data.status==1"
                                            @click="monitoringAction(0,data)" 
                                            v-tooltip="'Stop Monitoring'"
                                        )
                                            IconSet(type="stop")
                                        button(v-else-if="data.status==3" :disabled="true").btn-small.btn-primary No Data
                                        router-link(v-else :to="{name: 'pageMeIdTrackDetail', params: {id:data.id, source:'internal'}}").btn-small.btn-primary-o Detail
                                td
                                    .flex.items-center.justify-end.w-full
                                        button.btn-icon-40.m-hide(
                                            v-if="checkFeatureByAcl('me_id_track', 'edit')"
                                            @click="openModal(true, data)"
                                            v-tooltip="'Edit Description'"
                                        )
                                            IconSet(type="edit")
                                        button.btn-icon-40.m-hide(
                                            v-if="checkFeatureByAcl('me_id_track', 'delete') && data.status!=1"
                                            @click="deleteData(data.id, data.imei)"
                                            v-tooltip="'Delete'"
                                        )
                                            IconSet(type="delete")
                        tr(v-else)
                            td.text-center(:colspan="tableThead.length") No Data Available
            
            .box-table-footer(v-if="status_list.status !== 'loading'")
                .pagination
                    vue-ads-pagination(
                        :total-items="total_data"
                        :items-per-page="items_per_page"
                        :page="page"
                        :max-visible-pages="5"
                    )
                        template(slot="buttons" slot-scope="props")
                            vue-ads-page-button(
                                v-for="(button, key) in props.buttons"
                                :key="key"
                                v-bind="button"
                                :class="{'bg-yellow-dark': button.active}"
                                @page-change="page = button.page"
                                @range-change="start = button.start; end = button.end"
                            )
    //- popup 
    .popup-layer.is-show(v-if="openPopUp")
        .popup-container
            .popup-container__header
                .flex.items-center
                    h2.text-xl {{edit?'Edit Description':'New ME-ID Track'}}
                    //- h2.text-xl New/Edit IMEI

                button.btn-icon-40(
                    @click="openModal(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                .field.w-full.mb-6(v-if="!edit")
                    label.field__label.text-sm.font-bold IMEI<sup>*</sup>
                    .field__form.w-full
                        //- input.field-style.w-full(type="number" v-model="tmpImei" @input="inputImei" placeholder="Write IMEI here...")
                        input.field-style.w-full(type="number" v-model="model.imei" placeholder="Write IMEI here...")
                        //- .py-3.mr-2.message.text-xs.text-red-500(v-if="errorTmpMeIdTrack !== ''") {{ errorTmpMeIdTrack }}
                        .py-3.mr-2.text-xs.text-red-500(v-if="$v.model.imei.$error") IMEI is required &amp; length must be 15 char.
                .field.w-full.mb-6(v-if="!edit")
                    label.field__label.text-sm.font-bold Case<sup>*</sup>
                    .field__form.w-full
                        .relative(v-if="status_case_list.status === 'loading'")
                            .field-style
                                .field-style--mirroring.field-style--loading
                                    Spinner(show="true" size="32")
                        .field-multiple-select
                            multiselect(
                                v-if="case_management_list.data && case_management_list.data.length > 0"
                                :value="casesValue",
                                :options="case_management_list.data ? case_management_list.data : []",
                                :multiple="true",
                                placeholder="Select Case",
                                label="operation_name",
                                track-by="id",
                                @input="onChange"
                            )
                        .py-3.mr-2.text-xs.text-red-500(v-if="$v.model.case_id.$error") This case name is required.
                        //- input.field-style.w-full(type="text" v-model="tmpCase" @input="inputCase" placeholder="Write Case here...")
                        //- .py-3.mr-2.message.text-xs.text-red-500(v-if="errorTmpCase !== ''") {{ errorTmpCase }}
                .field.w-full.mb-6
                    label.field__label.text-sm.font-bold Description
                    .field__form.w-full
                        textarea.field-style.w-full(v-model="model.description" placeholder="Write a description here...")
                        //- textarea.field-style.w-full(v-model="tmpDesc" placeholder="Write a description here...")
                .field.w-full(v-if="!edit")
                    label.field__label.text-sm.font-bold Notes
                    .field__form.w-full
                        textarea.field-style.w-full(v-model="model.notes" placeholder="Write a notes here...")
                //- .field.w-full.mb-6(v-if="edit")
                    label.field__label.text-sm.font-bold Description
                    .field__form.w-full
                        textarea.field-style.w-full(v-model="editDesc" placeholder="Write a description here...")
                
            .popup-container__footer
                .flex.justify-end.w-full 
                    button.btn-primary-o(@click="openModal(false)") Cancel
                    button.btn-primary.ml-2(@click="submit($event)") Save

                //- .flex.justify-end.w-full(v-if="edit")
                    button.btn-primary(@click="update()") Update
                //- .flex.justify-end.w-full(v-else)
                    template(v-if="tmpImei && tmpImei.length>0 && errorTmpMeIdTrack == '' && tmpCase && tmpCase.length>0 && errorTmpCase == ''")
                        button.btn-primary(@click="create()") Save ME-ID Track
                    template(v-else)
                        button.btn-primary.btn-disabled(disabled) Save ME-ID Track
    
    .popup-layer(
        v-bind:class="{'is-show' : openNote}"
    )
        .popup-container
            .popup-container__header
                h2.text-xl {{imeiSelected}}'s Notes
                button.btn-icon-40(
                    @click="getNotes(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                .mb-4(v-if="checkFeatureByAcl('imei_sv', 'create')")
                    .field.w-full.mb-2
                        textarea.field-style(
                            v-model="tmpNote"
                            placeholder="Write new note here..."
                            style="min-height:60px"
                        )
                    .flex.justify-end
                        template(v-if="tmpNote && tmpNote.length>0")
                            button.btn-primary.btn.small(@click="addNote") Add note
                        template(v-else)
                            button.btn-primary.btn.small.btn-disabled(disabled) Add note
                .mb-4
                    .small-heading History Notes
                    template(v-if="status_notes_list.status == 'loading'")
                        .flex.flex-wrap.items-center.justify-center
                            Spinner(show="true" size="48")

                    .mb-4.pb-4.border-b(v-else-if="notes_list && notes_list.length > 0" v-for="note in notes_list")
                        p.mb-1 {{note.notes}}
                        small.text-xs.opacity-75 {{note.created_at}} • added by {{note.created_by?note.created_by:'-'}}

                    .flex.flex-wrap.items-center.justify-center(v-else)
                        strong.block.text-xs.uppercase.text-light.mb-4 No History Available
                    .block                            
                        template(v-if="status_notes_list.status != 'loading' && (notes_list && notes_list.length > 0)")
                            small.text-xs.opacity-75(v-if="!is_loading_next") {{notes_list.length}} of {{data_pagination.total_data}} items
                            .flex.flex-wrap.items-center.justify-center
                                template(v-if="is_loading_next")
                                    Spinner(show="true" size="48")
                                template(v-else)
                                    template(v-if="data_pagination.current_page<data_pagination.total_page")
                                        button.btn-small.btn-primary(@click="handleNextNotes") Load More                    
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import IconSet from '@/components/IconSet.vue'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect'

export default {
    name: 'pageMeIdTrackIndex',
    components: {
        IconSet,
        Spinner,
        DateRangePicker,
        VueAdsPagination,
        VueAdsPageButton,
        Multiselect,
    },

    data() {
        return {
            tableThead: [
                {
                    name: 'IMEI',
                },{
                    name: 'Case',
                },{
                    name: 'Description',
                },{
                    name: 'Notes',
                },{
                    name: 'Created By',
                },{
                    name: 'Created At',
                },{
                    name: 'Last Updated',
                },
            ],
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,
            openPopUp: false,
            
            // tmpImei: null,
            // tmpCase: null,
            // tmpDesc: '',
            // tmpNotes: '',
            // errorTmpMeIdTrack: '',
            // errorTmpCase: '',
            model:{
                imei: '',
                case_id: [],
                description: '',
                notes:'',
            },

            edit: false,
            editId: null,
            // editDesc: null,

            user_keyword:'',
            user_id:'',
            user_selected:false,

            openNote: false,

            imeiIdSelected: null,
            imeiSelected: null,
            tmpNote: null,
        }
    },
    validations() {
        return {
            model: {
                imei: {
                    required,
                    minLength: minLength(15),
                    maxLength: maxLength(15),
                },
                case_id: {
                    required,
                },
                description: {
                    maxLength: maxLength(255)
                },
                notes: {
                    maxLength: maxLength(255)
                },
            },
        }
    },
    
    computed: {   
        ...mapState('auth', ['user']),
        ...mapState('meIdTrack', [
            'status_list',
            'list',
            'status_notes_list',
            'notes_list',
            'is_loading_next',
            'data_pagination',
        ]),
        ...mapState({
            status_users: (state) => state.users.status_list,
            list_users: (state) => state.users.list,
            status_case_list: (state) => state.caseManagement.status_list,
            case_management_list: (state) => state.caseManagement.list,
        }),
        ...mapGetters({
            validationImei: 'config/getValidationImei',
        }),
        route_name() {
            return this.$route.name;
        },
        casesValue: {
            get() {
                if (!this.model.case_id) return null
                // console.log('=====onChange:get')
                // console.log(this.model.case_id)
                try {
                    return this.model.case_id.map(value => this.findOption(value.id)).filter(value => value.id)
                } catch (error) {
                    // console.log('error casesvalue')
                    // console.log(error)
                    return null
                }
            },
            set(v) {
                // console.log('=====casesValue:set')
                // console.log(v)
                this.model.case_id=v.map(value => {
                    let rObj = {}
                    rObj['id'] = value.id
                    return rObj
                })
            }
        },
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'pageMeIdTrackIndex') {
                if (oldData !== 'pageMeIdTrackIndex') {
                    this.defaultValue()
                }

                this.getData();
            }
        },
        list(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);
            } else {
                this.total_data = 0;
            }
        },
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },
        user_keyword(newData) {
            if (newData.length < 3)
                this.$store.commit('users/setList', {});
        },
    },
    methods: {
        searchUser() {
            // clear timeout variable
            clearTimeout(this.timeout);

            var self = this;
            this.timeout = setTimeout(function () {
                if (self.user_keyword.length > 2) {
                    self.$store.commit('users/setList', {});
                    self.$store.dispatch('users/getList', [30, 1, self.user_keyword]);
                }
            }, 650);
        },
        selectUser(user){
            this.user_keyword = user.name
            this.user_id = user.id
            this.$store.commit('users/setList', {});
            this.user_selected = true
            this.getData()
        },
        setEmptyUser() {
            this.user_keyword = ''
            this.user_id=''
            this.user_selected = false
            this.getData()
        },
        defaultValue() {
            this.items_per_page = 10;
            this.page = 0;
            this.keyword = '';
            this.$store.commit('meIdTrack/setList', {});
        },
        getData() {
            // for dispatch get data
            if (this.route_name === 'pageMeIdTrackIndex') {
                this.$store.dispatch('meIdTrack/getList', [this.items_per_page, (this.page + 1), this.keyword, this.user_id]);
                // if (this.list.total_page && this.list.total_page > 1)
                    // this.pagination();
            }
        },
        pageChange(page) {
            this.page = page;
        },
        sizeChange() {
            this.page = 0;
        },
        searchKeyword() {
            clearTimeout(this.timeout);            
            var self = this;
            this.timeout = setTimeout(function () {
                self.page = 0;
                self.$store.commit('meIdTrack/setList', {});
                self.getData();
            }, 650);
        },
        openModal(open = true, data=null) {
            // hide scroll bar if true
            if(open) {
                this.$store.dispatch('caseManagement/getList', [100, 1, null, 'active']);
                EventEmit.$emit('overflowHidden', true);
            } else {
                EventEmit.$emit('overflowHidden', false);
            }
            this.openPopUp = !this.openPopUp
            if (data != null) {
                // console.log(data)
                this.edit = true
                this.editId = data.id
                this.model.description = data.description
                // this.editDesc = data.description
            } else {
                this.edit = false
                this.setEmptyModel(true)
                // this.tmpImei = null
                // this.tmpCase = null
                // this.tmpDesc = ''
                // this.tmpNotes = ''
                // this.errorTmpMeIdTrack=''
                // this.errorTmpCase=''
            }
        },
        setEmptyModel(all=true) {
            this.$nextTick(() => { this.$v.$reset(); });
            for (let key in Object.keys(this.model)) {
                if (all === true)
                    if(["case_id"].indexOf(key) > -1)
                        this.model[Object.keys(this.model)[key]] = [];
                    else
                        this.model[Object.keys(this.model)[key]] = '';
            }
        },
        inputImei(event){
            const value = event.target.value
            if (String(value).length < this.validationImei.min) {
                // this.clueValTmp = value
                this.errorTmpMeIdTrack = "IMEI minimum length: " + this.validationImei.min + " characters"
            } else if(String(value).length > this.validationImei.max) {
                this.errorTmpMeIdTrack = "IMEI maximum length: " + this.validationImei.max + " characters"
            } else {
                this.errorTmpMeIdTrack = ""
            }
        },
        inputCase(event){
            const value = event.target.value
            if (String(value).length == 0) {
                this.errorTmpCase = "Case is required"
            } else {
                this.errorTmpCase = ""
            }
        },
        onChange (value) {
            this.casesValue = value
        },
        findOption(value) {
            // console.log('=====findOption')
            // console.log(value)
            const opt = this.case_management_list.data
            try {
                return opt.find(option => option['id'] === value)
            } catch (error) {
                // console.log('error')
                // console.log(error)
                return opt
            }
        },
        submit(e) {
            if (this.edit) {
                this.update()                
            } else {
                e.preventDefault();
                this.$v.$touch();

                if (this.$v.model.$invalid) {
                    for (let key in Object.keys(this.$v.model)) {
                        const input = Object.keys(this.$v.model)[key];
                        if (input.includes("$")) return false;
                        if (this.$v.model[input].$error) {
                            break;
                        }
                    }
                    // EventEmit.$emit('error', 'Please fill the form correctly.');
                    // EventEmit.$emit('showLoading', false);
                    window.scrollTo(0,0);
                    return;
                } 
                this.create()
            }
        },
        create(){
            EventEmit.$emit('showLoading', true);
            let model_data = Object.keys(this.model).map(
                (key) => (["case_id"].indexOf(key) > -1)?[JSON.stringify(this.model[key])]:this.model[key]
            );
            this.$store.dispatch('meIdTrack/create', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {
                        // this.getNotes(false)
                        this.setEmptyModel(true)
                        // this.tmpImei = null
                        // this.tmpCase = null
                        // this.tmpDesc = ''
                        // this.tmpNotes = ''
                        // this.errorTmpMeIdTrack = ""
                        // this.errorTmpCase = ""

                        this.getData();

                        this.$swal.fire({
                            icon: 'success',
                            title: 'ME-ID Track successfully added!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message) {
                            EventEmit.$emit('error', resp.data.message);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });
            this.openModal(false);
        },
        update(){
            EventEmit.$emit('showLoading', true);
            this.$store.dispatch('meIdTrack/update', [this.editId, this.model.description])
                .then((resp) => {
                    if (resp.result === 'success') {
                        // this.getNotes(false)
                        this.editId = null
                        this.model.description = null
                        
                        this.getData();

                        this.$swal.fire({
                            icon: 'success',
                            title: 'ME-ID Track successfully updated!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message) {
                            EventEmit.$emit('error', resp.data.message);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });
            this.openModal(false);
        },
        deleteData(id, name) {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to delete?',
                text: 'ME-ID Track : ' + name,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {

                if (result.isConfirmed) {
                    EventEmit.$emit('showLoading', true);

                    this.$store.dispatch('meIdTrack/delete', [id])
                    .then((resp) => {
                        if (resp === 'success') {
                            this.page = 0;

                            this.getData();

                            this.$swal.fire({
                                icon: 'success',
                                title: 'Data successfully deleted!',
                                timer: 3000,
                            });
                        } else {
                            if (resp.data && resp.data.message) {
                                EventEmit.$emit('error', resp.data.message);
                            } else {
                                EventEmit.$emit('error');
                            }
                        }
                    });
                }
            });
        },
        getNotes(open=true, imei_id=null,imei=null) {
            this.$store.commit('meIdTrack/setNotesList', {});
            if(open){
                this.imeiIdSelected = imei_id
                this.imeiSelected = imei
                this.$store.dispatch('meIdTrack/getNotesList', [this.imeiIdSelected]);
                this.openNote = true
                EventEmit.$emit('overflowHidden', true);
            } else {
                this.setEmptyNotes();
                this.setDefaulDataNotes();
                EventEmit.$emit('overflowHidden', false);
            }
        },
        handleNextNotes() {
            // console.log(imei_id)
            this.$store.commit('meIdTrack/setIsLoadingNext', true);        
            this.$store.dispatch('meIdTrack/getNotesList', [this.imeiIdSelected, true]);
        },
        addNote(){
            EventEmit.$emit('showLoading', true);
            this.$store.dispatch('meIdTrack/createNotes', [this.imeiIdSelected, this.tmpNote])
                .then((resp) => {
                    if (resp.result === 'success') {
                        // this.getNotes(false)
                        this.tmpNote = null
                        this.setDefaulDataNotes()

                        this.getNotes(true, this.imeiIdSelected, this.imeiSelected);
                        this.getData();

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Note successfully added!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message) {
                            EventEmit.$emit('error', resp.data.message);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });
        },
        setEmptyNotes(){
            this.imeiIdSelected = null
            this.imeiSelected = null
            this.tmpNote = null
            this.openNote = false
        },
        setDefaulDataNotes(){
            // console.log('else')
            // this.dataNotes = [];
            // this.totalNotes = 0;
            // this.limitNotes = 10;
            // this.pageNotes=0;
            // this.totalPageNotes=1;
            const dataPagination = { 
                current_page: 1,
                has_next: false,
                has_previous: false,
                next_page_number: 1,
                per_page: 10,
                previous_page_number: null,
                total_data: 0,
                total_page: 1,
            }
            this.$store.commit('meIdTrack/setDataPagination', dataPagination);
        },
        monitoringAction(status=1,data=null) {
            if(data==null){
                data=this.monitoringData
            }
            var title = 'Are you sure you want to start monitoring ?'
            if (status === 0) {
                title = 'Are you sure you want to stop monitoring?'
            }
            this.$swal.fire({
                icon: 'warning',
                title: title,
                text: 'IMEI : ' + data.imei,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {
                // console.log('on result');

                if (result.isConfirmed) {
                    // console.log(result.value);

                    EventEmit.$emit('showLoading', true);
                    if(status === 1) {
                        EventEmit.$emit('overflowHidden', false);
                    }

                    this.$store.dispatch('meIdTrack/update', [data.id, null, status])
                    .then((resp) => {
                        // console.log('postMonitoring');
                        // console.log(resp);
                        if (resp.result === 'success') {
                            this.page = 0;
                            // this.monitoringModal(false);

                            this.getData();

                            this.$swal.fire({
                                icon: 'success',
                                title: resp.data.message,
                                timer: 3000,
                            });
                        } else {
                            if (resp.data.message) {
                                EventEmit.$emit('error', resp.data.message);
                            } else {
                                EventEmit.$emit('error');
                            }
                        }
                    });
                }
            });
        },
    },

    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        }
    },
    mounted() {
        this.defaultValue()
    },
    created() {
        this.getData();
    },
}
</script>


<style lang="sass">
.btn-close-search
    @apply absolute top-0 right-0 flex items-center justify-center
    width: 46px
    height: 46px
.box-find
    width: 100%
    max-width: 400px
    background-color: $color-secondary-500
    border-radius: 4px
    position: relative

.container-box-users
    top: 100%
    z-index: 100
    background-color: $color-secondary-700
    width: 100%

.box-users
    .item-user
        @apply .flex .items-center .p-2 .border-b .cursor-pointer
        &:hover 
            background-color: $color-secondary-600
        &:last-child
            border-bottom: 0px
.box-find-selected
    background-color: $color-secondary-600
    padding-top: 6px
    @apply .absolute .inset-0 .rounded .px-2 .border
    border-color: #2d3061

</style>